import React, { useState } from 'react';
import { auth } from './firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import './Login.css';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        onLogin(userCredential.user);
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        onLogin(userCredential.user);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <div style={{textAlign: 'center'}}>
          <img style={{height: '75px'}} src={`https://wixmp-fe53c9ff592a4da924211f23.wixmp.com/users/df9807cc-9ec3-4aac-8629-301af0b4d474/design-previews/f97f02d7-8942-475e-858b-0931cadc784f/1718321361048-transparentThumbnail.png`} alt={'logo'} />
        </div>
        <h3>Welcome {isSignUp ? 'Home' : 'Back'}!</h3>
        {error && <div className="error-message">{error}</div>}
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
        <p>
          {isSignUp ? "Already have an account? " : "Don't have an account? "}
          {/* <button type="button" onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? 'Sign In' : 'Request access'}
          </button> */}
          <button type="button" onClick={() => { window.location.href = 'mailto:info@cassafi.com?subject=ACCESS'}}>
            {isSignUp ? 'Sign In' : 'Request access'}
            </button>
        </p>
        <div style={{fontSize: '12px', color: 'gray', paddingTop: '20px'}}>⚙️ Version -- pre-alpha</div>
      </form>
    </div>
  );
}

export default Login;