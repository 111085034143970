import React, { useState } from 'react';

const questions = [
  {
    id: 1,
    question: "What's your target home price range?",
    options: [
      { id: 'a', text: "Under $300,000" },
      { id: 'b', text: "$300,000 - $500,000" },
      { id: 'c', text: "$500,000 - $750,000" },
      { id: 'd', text: "Over $750,000" }
    ]
  },
  {
    id: 2,
    question: "What type of property are you looking for?",
    options: [
      { id: 'a', text: "Single Family Home" },
      { id: 'b', text: "Townhouse" },
      { id: 'c', text: "Condo" },
      { id: 'd', text: "Multi-Family" }
    ]
  },
  {
    id: 3,
    question: "When are you planning to buy?",
    options: [
      { id: 'a', text: "Within 1 month" },
      { id: 'b', text: "1-3 months" },
      { id: 'c', text: "3-6 months" },
      { id: 'd', text: "6+ months" }
    ]
  }
];

const QuestionnaireModal = ({ isOpen, onClose, onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(-1); // Start at -1 for welcome screen
  const [answers, setAnswers] = useState({});

  if (!isOpen) return null;

  const handleAnswer = (questionId, answerId, answerText) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: { id: answerId, text: answerText }
    }));

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      onComplete(answers);
    }
  };

  const renderWelcomeScreen = () => (
    <div style={{ textAlign: 'center', padding: '1rem' }}>
      <div style={{ marginBottom: '2rem' }}>
        <h2 style={{ 
          fontSize: '1.5rem', 
          marginBottom: '1rem',
          color: '#3b82f6'
        }}>
          Welcome to A New Way of Home Buying
        </h2>
        <p style={{ 
          color: '#E5E7EB', 
          lineHeight: '1.6',
          marginBottom: '1rem'
        }}>
          Let's personalize your experience by understanding your home buying preferences.
        </p>
        <p style={{ 
          color: '#9CA3AF',
          fontSize: '0.9rem',
          marginBottom: '2rem'
        }}>
          This will help us provide you with more relevant information and guidance throughout your journey.
        </p>
      </div>
      <button
        onClick={() => setCurrentQuestion(0)}
        style={{
          padding: '1rem 2rem',
          backgroundColor: '#3b82f6',
          border: 'none',
          borderRadius: '4px',
          color: 'white',
          cursor: 'pointer',
          fontSize: '1.1rem',
          transition: 'background-color 0.2s',
          width: '100%',
          maxWidth: '300px'
        }}
      >
        Let's Get Started →
      </button>
    </div>
  );

  const renderQuestion = () => {
    const currentQ = questions[currentQuestion];
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <h2 style={{ margin: 0 }}>Question {currentQuestion + 1} of {questions.length}</h2>
          <button 
            onClick={onClose}
            style={{
              background: 'none',
              border: 'none',
              color: 'white',
              fontSize: '1.5rem',
              cursor: 'pointer'
            }}
          >
            ×
          </button>
        </div>

        <div style={{ marginBottom: '2rem' }}>
          <h3>{currentQ.question}</h3>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {currentQ.options.map((option) => (
            <button
              key={option.id}
              onClick={() => handleAnswer(currentQ.id, option.id, option.text)}
              style={{
                padding: '1rem',
                backgroundColor: '#3b82f6',
                border: 'none',
                borderRadius: '4px',
                color: 'white',
                cursor: 'pointer',
                transition: 'background-color 0.2s'
              }}
            >
              {option.text}
            </button>
          ))}
        </div>

        <div style={{ 
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <button
            onClick={() => setCurrentQuestion(Math.max(0, currentQuestion - 1))}
            disabled={currentQuestion === 0}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: currentQuestion === 0 ? '#4B5563' : '#3b82f6',
              border: 'none',
              borderRadius: '4px',
              color: 'white',
              cursor: currentQuestion === 0 ? 'not-allowed' : 'pointer',
            }}
          >
            Previous
          </button>
          <div style={{ color: '#9CA3AF' }}>
            {currentQuestion + 1} / {questions.length}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="modal-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div className="modal-content" style={{
        backgroundColor: '#2C2C2C',
        padding: '2rem',
        borderRadius: '8px',
        width: '90%',
        maxWidth: '500px',
        color: 'white'
      }}>
        {currentQuestion === -1 ? renderWelcomeScreen() : renderQuestion()}
      </div>
    </div>
  );
};

export default QuestionnaireModal;